import * as Tabs from "@radix-ui/react-tabs";
import { classNames as cn } from "../../lib/utils/classNames";
import { ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export interface TabsConfig {
  ariaLabel: string;
  defaultValue: string;
  tabs: TabData[];
  bgColor: TabsBackgroundColor;
  resizeHandle?: ReactNode;
  size?: TabsSize;
}

export type TabsBackgroundColor = "yellow" | "white";

export type TabsSize = "sm" | "md";

export interface TabData {
  value: string;
  content: ReactNode | string | null;
}

const defaultTabsConfig: TabsConfig = {
  ariaLabel: "tabs list",
  tabs: [],
  defaultValue: "",
  bgColor: "white",
  size: "md",
};

const contentColorMap = {
  white: "bg-white",
  yellow: "bg-yellow-lighter",
};

const triggerColorMap = {
  white: "data-[state=active]:bg-white",
  yellow: "data-[state=active]:bg-yellow-lighter",
};

export function FolderTabs({
  tabsConfig = defaultTabsConfig,
  className,
}: {
  tabsConfig: TabsConfig;
  className?: string;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get("tab") ?? tabsConfig.defaultValue);
  const handleTabChange = (value: string) => {
    setSearchParams(prev => {
      prev.set("tab", value);
      return prev;
    });
    setActiveTab(value);
  };

  useEffect(() => {
    setActiveTab(searchParams.get("tab") ?? tabsConfig.defaultValue);
  }, [searchParams]);

  return (
    <Tabs.Root
      value={activeTab}
      defaultValue={tabsConfig.defaultValue}
      className={className}
      onValueChange={handleTabChange}
    >
      <Tabs.List
        className={cn(
          "flex w-full gap-1 px-1 shadow-bottomline md:mx-auto md:max-w-screen-lg md:shadow-none",
          className,
        )}
        aria-label={`tab list`}
      >
        {tabsConfig.tabs.map(tab => (
          <TabsTrigger
            bgColor={tabsConfig.bgColor}
            key={tab.value}
            size={tabsConfig.size}
            value={tab.value}
          >
            {tab.value}
          </TabsTrigger>
        ))}
      </Tabs.List>
      {tabsConfig.resizeHandle}
      {tabsConfig.tabs.map(tab => (
        <Tabs.TabsContent
          className={contentColorMap[tabsConfig.bgColor]}
          key={tab.value}
          value={tab.value}
        >
          {tab.content}
        </Tabs.TabsContent>
      ))}
    </Tabs.Root>
  );
}

const TabsTrigger = ({
  children,
  className = "",
  bgColor,
  size = "md",
  ...props
}: {
  children: string;
  className?: string;
  bgColor: TabsBackgroundColor;
  size?: TabsSize;
  value: string;
}) => {
  return (
    <Tabs.Trigger
      className={cn(
        `box-border bg-gray-300 ${triggerColorMap[bgColor]} md:border-box flex-1 rounded-t border-b-2 border-transparent border-b-black p-2.5 font-Altivo text-2xl font-black uppercase text-zinc-600 data-[state=active]:border-2 data-[state=active]:border-b-0 data-[state=active]:border-solid data-[state=active]:border-x-black data-[state=active]:border-t-black data-[state=active]:px-2 data-[state=active]:pt-1.5 data-[state=active]:text-black md:flex-none md:rounded md:border-2 md:border-transparent md:p-2.5 md:data-[state=active]:border-2 md:data-[state=active]:border-black md:data-[state=active]:p-2.5`,
        size === "sm"
          ? "text-md min-w-24 p-1 leading-4 data-[state=active]:px-0.5 data-[state=active]:pt-px"
          : "",
        className,
      )}
      {...props}
    >
      {children}
    </Tabs.Trigger>
  );
};
