import { ContentContainer } from "../../components/ContentContainer";
import { EmptyDisplay } from "../../components/EmptyDisplay";
import { LoadingSpinner } from "../../components/Spinner";
import { SDTable } from "../../components/table/SDTable";
import { SDTableContainer, SDTableContainerHeader } from "../../components/table/SDTableContainer";
import { configureSessionTableProps } from "../../components/table/SDTableHelpers";
import { usePlayersBySession } from "../../lib/data/players";
import { useSessionStats, useSessionTitles } from "../../lib/data/stats";

interface SessionStatsTableProps {
  sessionId: string;
}
export function SessionStatsTable({ sessionId }: SessionStatsTableProps) {
  const { data: stats } = useSessionStats(sessionId);
  const { data: players } = usePlayersBySession(sessionId);
  const { mostRebounds, mvp } = useSessionTitles(sessionId);

  console.log(mostRebounds, mvp);
  if (!players?.length)
    return (
      <ContentContainer>
        <EmptyDisplay text={"No stats, yet."} subtext={"Add players to your session!"} />
      </ContentContainer>
    );
  if (!stats) return <LoadingSpinner className="mt-8" />;
  const aggregateTableData = configureSessionTableProps(players, stats);

  return (
    <ContentContainer>
      <SDTableContainer>
        <SDTableContainerHeader title={"Aggregate"} detail={`${players.length} players`} />
        <SDTable headers={aggregateTableData[0]} data={aggregateTableData[1]}></SDTable>
      </SDTableContainer>
    </ContentContainer>
  );
}
