import { Button, IconButton } from "../../components/Button";
import { LoadingSpinner } from "../../components/Spinner";
import { GameEvent, PlayerWithDetails } from "../../lib/data/models";
import { usePlayersByGame } from "../../lib/data/players";
import { useDeleteStat, useGameEventsRefresher } from "../../lib/data/stats";
import { fullName, shortDateTime } from "../../lib/utils/format";
import { Nameable } from "../../lib/utils/types";
import { ErrorPage } from "../pages/ErrorPage";
import { feedMessage } from "./messages";
import { ContentContainer } from "../../components/ContentContainer";
import { TbRefresh } from "react-icons/tb";
import { useToast } from "../../components/ui/use-toast";
import { useMe } from "../../lib/data/user";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { FaEllipsisH } from "react-icons/fa";
import { PlayerAvatar } from "../../components/avatar/PlayerAvatar";
import { classNames } from "../../lib/utils/classNames";
import { FaUndo } from "react-icons/fa";
import { EmptyDisplay } from "../../components/EmptyDisplay";
interface StatFeedProps {
  gameId: string;
}
export function StatFeed({ gameId }: StatFeedProps) {
  const { toast } = useToast();
  const { data: me } = useMe();
  const { data: teams = { homeTeam: [], awayTeam: [] } } = usePlayersByGame(gameId);
  const { status, data, fetchNextPage, hasNextPage, refetch } = useGameEventsRefresher(gameId);
  const { mutateAsync: deleteStat } = useDeleteStat(gameId);

  const homeTeamPlayers = teams.homeTeam.map(p => p.id);
  const awayTeamPlayers = teams.awayTeam.map(p => p.id);
  const allPlayers = teams.homeTeam.concat(teams.awayTeam);
  const playerInTeam = (playerId: string) => allPlayers.find(p => p.id === playerId);
  const isInHomeTeam = (playerId: string) => homeTeamPlayers.includes(playerId);
  const isInAwayTeam = (playerId: string) => awayTeamPlayers.includes(playerId);

  const events = data?.pages.flat() ?? [];

  const onRefresh = async () => {
    await refetch();
    toast({ title: "Success!", description: "refreshed" });
  };
  if (status === "error") return <ErrorPage />;
  return (
    <ContentContainer>
      <div className="flex flex-col gap-4">
        <div className="heading-2 flex flex-row items-center gap-3">
          <span>play-by-play</span>
          <IconButton label="refresh feed" intent="iconOnly" onClick={onRefresh}>
            <TbRefresh size="20" />
          </IconButton>
        </div>
        {status === "pending" ? (
          <LoadingSpinner className="mt-8" />
        ) : (
          <>
            {events.map(event => (
              <StatCard
                key={event.id}
                player={playerInTeam(event.playerId)}
                event={event}
                isCreator={me?.id === event.loggerUserId}
                teamText={
                  isInHomeTeam(event.playerId)
                    ? "Team 1"
                    : isInAwayTeam(event.playerId)
                      ? "Team 2"
                      : "N/A"
                }
                deleteStat={deleteStat}
              />
            ))}
            {hasNextPage ? (
              <Button intent="light" label="see more" onClick={async () => await fetchNextPage()} />
            ) : (
              <></>
            )}
          </>
        )}
        {events.length === 0 && (
          <EmptyDisplay
            image="StretchLegPull"
            text="Ready up!"
            subtext="Waiting for the action to kick off!"
          />
        )}
      </div>
    </ContentContainer>
  );
}

interface StatCardProps {
  className?: string;
  player: PlayerWithDetails | undefined;
  event: GameEvent & { logger?: Nameable };
  teamText: string;
  isCreator: boolean;
  statKeepingMode?: boolean;
  deleteStat: (statId: string) => void;
}
export function StatCard({
  player,
  event,
  teamText,
  deleteStat,
  className,
  isCreator,
  statKeepingMode,
}: StatCardProps) {
  const ellipsisMenu = (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <FaEllipsisH className="float-right text-grey-dark" size="20" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white-custom">
        <DropdownMenuItem onMouseDown={async () => await deleteStat(event.id)}>
          <FaUndo className="h-[18px] w-[32px] fill-black pb-[3px] pr-2" />
          <div className="cursor-pointer">Undo</div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );

  return player ? (
    <div
      className={classNames(
        "w-full max-w-screen-lg rounded-lg border-2 border-transparent bg-white p-2 shadow-md",
        className,
      )}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-2">
          <PlayerAvatar playerId={player.id} user={player.user} />
          <div>
            <div className="text-xs">{teamText}</div>
            <div className="text-sm font-medium">{fullName(player.user)}</div>
          </div>
        </div>
        {isCreator && !statKeepingMode ? (
          <div className="flex-1 text-right">{ellipsisMenu}</div>
        ) : (
          <IconButton
            className="text-right"
            intent="statFeed"
            label="undo"
            onClick={async () => await deleteStat(event.id)}
          >
            <FaUndo className="fill-black"></FaUndo>
          </IconButton>
        )}
      </div>
      <div className="mt-2 flex flex-col gap-1">
        <div className="flex flex-row gap-4 text-sm">
          <div>{shortDateTime(event.createdAt)}</div>
          <div>{feedMessage(event)}</div>
        </div>
        <div className="text-gray-500">
          {event.logger && <div className="text-xs">logged by: {fullName(event.logger)}</div>}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
