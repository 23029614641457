import * as dt from "date-fns";
import { LoadingSpinner } from "../../components/Spinner";
import { usePlayersBySession } from "../../lib/data/players";
import { useHostsBySession, useSession } from "../../lib/data/session";
import { BasketballDunkYellow } from "../../components/svgr";
import { AvatarStack } from "../../components/avatar/AvatarStack";
import { IconButton } from "../../components/Button";
import { Dialog } from "@radix-ui/react-dialog";
import { useState } from "react";
import { DialogContent, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { PlayersListWithRemove } from "../../components/PlayersList";
import { Badge } from "../../components/ui/badge";

const rosterTabTypes = ["Players", "Hosts"] as const;
export type FilterTypes = (typeof rosterTabTypes)[number];

export function SessionInformation({ sessionId }: { sessionId: string }) {
  const { isLoading, data: session } = useSession(sessionId);
  const [showRoster, setShowRoster] = useState(false);
  const [rosterState, setRosterState] = useState<FilterTypes>("Players");
  const { data: playersFromCollection = [] } = usePlayersBySession(sessionId);
  const { data: hosts = [] } = useHostsBySession(sessionId);
  if (isLoading || !session) return <LoadingSpinner className="mt-2" />;
  const users = playersFromCollection.map(p => p.user);
  const dateString = dt.format(new Date(session.startDate), "M/dd/yy • hh:mma");

  const rosterCountMap: Record<FilterTypes, number> = {
    Players: playersFromCollection.length,
    Hosts: hosts.length,
  };

  const rosterModal = (
    <Dialog open={showRoster} onOpenChange={setShowRoster}>
      <DialogContent className="bg-white sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>Roster</DialogTitle>
        </DialogHeader>
        <div className="flex items-center gap-2 px-2.5 pb-2 pt-4 sm:px-5">
          {rosterTabTypes.map(tabType => {
            const selected = tabType === rosterState;
            return (
              <Badge
                key={tabType}
                state={selected ? "selected" : "unselected"}
                color={selected ? "sdGreen" : "white"}
                onClick={() => setRosterState(tabType)}
              >
                {`${tabType}: ${rosterCountMap[tabType]}`}
              </Badge>
            );
          })}
        </div>
        {rosterState === "Players" ? (
          <PlayersListWithRemove players={playersFromCollection} />
        ) : (
          <PlayersListWithRemove players={hosts} />
        )}
      </DialogContent>
    </Dialog>
  );

  return (
    <div className="w-full px-2.5 py-4">
      {rosterModal}
      <div className="flex flex-row gap-3">
        <div className="flex flex-col">
          <BasketballDunkYellow aria-label="basketball dunk icon" className="w-20 md:w-[100px]" />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <h3 className="heading-3 leading-none md:text-2xl">{dateString}</h3>
            <div className="text-sm">{session.title}</div>
          </div>
          <IconButton
            className="z-10"
            intent="iconOnly"
            label="Show player list"
            onClick={() => setShowRoster(true)}
          >
            <AvatarStack users={users} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
